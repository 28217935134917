/* Styling for the Plethora Tabs + Accordions plugin */
.lightweight-accordion {
  @apply w-full overflow-hidden;

  summary {
    @apply flex items-center justify-between;

    &::marker,
    &::-webkit-details-marker {
      content: '';
      display: none;
    }

    &::after {
      content: '';
      @apply grid min-h-[24px] min-w-[24px] place-content-center rounded-full;
      margin-left: 0.7rem;
      background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 54 54' xmlns='http://www.w3.org/2000/svg' width='24px' height='24px'><circle fill='%2300FFAB' cx='27' cy='27' r='27'/><path fill='%23080808' d='m29.61,37.24l14.53-14.53c1.15-1.15,1.15-3.02,0-4.17h0c-1.15-1.15-3.02-1.15-4.17,0l-12.97,12.97-12.97-12.97c-1.15-1.15-3.02-1.15-4.17,0h0c-1.15,1.15-1.15,3.02,0,4.17l14.53,14.53c1.44,1.44,3.78,1.44,5.22,0Z'/></svg>");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      transition: transform 0.15s ease-in-out;
    }

    & > * {
      @apply text-lg font-normal uppercase;
    }
  }

  details {
    &[open] {
      summary {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  details[open] summary ~ * {
    animation: accordionopen 0.3s ease-in-out;
  }

  &.bordered {
    @apply border border-grey px-12-24 py-8-16;
  }

  .lightweight-accordion-body {
    @apply pt-4-8;
  }
}

@keyframes accordionopen {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
