/* Sub Menu dropdown Styling
---------------------------------------------------------------------------- */
.submenu-container {
  --border-color: rgba(235 235 235 / 100%);
  --push-top: 10px;

  @apply absolute left-1/2 top-full;
  @apply z-50;

  padding-top: var(--push-top);
  width: max-content;
  will-change: transform;
  transform-origin: center top;
  transform: translate3d(-50%, -9999px, 0);
  /* transform: translate3d(-50%, 0px, 0); */

  /* Arrow */
  &::after,
  &::before {
    bottom: calc(99% - var(--push-top));
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(213 136 136 / 0%);
    border-bottom-color: theme(colors.white);
    border-width: 6px;
    margin-left: -6px;
  }

  &::before {
    border-color: rgba(194 225 245 / 0%);
    border-bottom-color: var(--border-color);
    border-width: 8px;
    margin-left: -8px;
  }

  .submenu {
    @apply grid;
    @apply border border-[rgb(235,235,235)] bg-white px-8-16 py-8-16;
    @apply px-24-48;
    min-width: 120px;

    .nav-item {
      @apply py-12-24;

      background: linear-gradient(
        to right,
        rgba(113 30 70 / 80%),
        rgba(113 30 70 / 80%)
      );
      background-size: 0 0.15em;
      background-position: 0 100%;
      background-repeat: no-repeat;
      transition: background-size 400ms;

      &:hover,
      &:focus {
        background-size: 2rem 0.15em;
      }
    }
  }
}

.menu-item-has-children {
  &:hover,
  &:focus-within {
    .submenu-container {
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-name: primary-nav-submenu;
    }
  }
}

/* Hamburger Menu Styling
---------------------------------------------------------------------------- */
#hamburger-icon {
  @apply relative z-[999] grid place-content-center;
  @apply -mr-2-4 rounded-sm px-[0.375rem] py-[0.5rem];
  @apply focus:outline-none focus:ring-1 focus:ring-tech-blue;

  .icon {
    @apply bg-transparent;
    width: 28px;
    height: 18px;
    padding: 0;
    margin: 0;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      @apply bg-tech-blue;
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(2) {
        top: 8px;
      }

      &:nth-child(3) {
        top: 16px;
      }
    }
  }
}

#hamburger-icon.open {
  .icon {
    span {
      @apply bg-tech-blue;
    }

    span:nth-child(1) {
      top: 8px;
      transform: rotate(135deg);
    }

    span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    span:nth-child(3) {
      top: 8px;
      transform: rotate(-135deg);
    }
  }
}

/* Off Canvas Navigation Styling
---------------------------------------------------------------------------- */
.off-canvas-nav {
  @apply h-screen w-screen overflow-hidden;
  @apply inset-0 fixed;

  .background {
    @apply absolute h-screen w-screen;
    background: theme(colors.black);
  }

  .enter {
    transform: translate3d(0%, -100%, 0);
    animation-name: background-animation-open;
    animation-timing-function: ease-out;
    animation-fill-mode: backwards;

    &.bg-layer-1 {
      animation-duration: 0.8s;
    }
  }

  .leave {
    animation-name: background-animation-close;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;

    &.bg-layer-1 {
      animation-duration: 0.4s;
    }
  }
}

@keyframes background-animation-open {
  0% {
    opacity: 0;
    transform: translate3d(0%, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0%, 0%, 0);
  }
}

@keyframes background-animation-close {
  0% {
    opacity: 1;
    transform: translate3d(0%, 0%, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0%, -100%, 0);
  }
}

@keyframes primary-nav-submenu {
  0% {
    opacity: 0;
    /* The extreme negative y is because press events were being captured on mobile
    I'm assuming iOS registers a press for a millisecond or 2 and it hits the subnav */
    transform: translate3d(-50%, -300%, 0);
  }

  1% {
    opacity: 0;
    transform: translate3d(-50%, -5%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(-50%, 0%, 0);
  }
}
