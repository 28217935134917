.animation-homepage-hero {
  /* Background animation */
  opacity: 0;
  filter: blur(8px);
  transform: scale(0.8);
  animation: background-fade 2.5s 0.5s forwards;

  /* H1 animation */
  .animation-part-heading {
    opacity: 0;
    filter: blur(8px);
    transform: scale(1.1);
    animation: h1-fade 2.5s 1s forwards;
  }

  /* H2 animation */
  .animation-part-subheading {
    opacity: 0;
    animation: h2-fade 2.5s 2.5s forwards;
  }

  /* Group and buttons animation */
  .animation-part-copy {
    opacity: 0;
    animation: fade-in-group-and-buttons 1.5s 3.5s forwards;
  }

  .animation-part-buttons {
    opacity: 0;
    animation: fade-in-group-and-buttons 1.5s 4.75s forwards;
  }

  .animation-part-meta {
    opacity: 0;
    transform: translateY(20px);
    animation: fade-in-group-and-buttons 1.5s 4.75s forwards;
  }
}

/* Keyframes */
@keyframes background-fade {
  0% {
    opacity: 0;
    filter: blur(8px);
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

@keyframes h1-fade {
  0% {
    opacity: 0;
    filter: blur(8px);
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

@keyframes h2-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-group-and-buttons {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
