.wp-input-container {
  @apply relative;

  label {
    @apply mb-2-4 block text-sm font-semibold;
  }
}

.wp-input,
.wp-select,
.wp-block-search__input {
  @apply relative block w-full border px-[24px] py-[14px];
  @apply focus:outline-none focus:ring-2;
  @apply focus:ring-gowth-green;
}

.wp-input {
  @apply text-lg;
}

.wp-select {
  @apply pr-[48px] font-bold;

  option {
    @apply !text-black;
  }
}

.wp-input.is-style-transparent,
.wp-select.is-style-transparent {
  @apply border-0 bg-transparent shadow-none;
}

.wp-input.is-style-outline-white {
  @apply text-white placeholder-white/80;
  @apply border;
  @apply border-white;
}

.wpforms-container {
  .wpforms-hidden {
    @apply hidden;
  }

  .wpforms-field-label.wpforms-label-hide,
  .wpforms-field-sublabel.wpforms-sublabel-hide {
    @apply sr-only;
  }
}

.form-cta.wpforms-container {
  @apply mb-0;

  .wpforms-form {
    @apply grid;
  }

  .wpforms-field-container {
    @apply grid gap-12-24;

    .wpforms-field {
      @apply w-full;

      .wpforms-field-label {
        @apply text-sm font-semibold uppercase tracking-[0.8px] text-inherit;
      }

      input {
        @apply bg-white/60;
        @apply focus:bg-white focus:outline-none focus:ring-2 focus:ring-tech-blue;
      }

      input.wpforms-error {
        @apply border-[2px] border-[#FF0D11] ring-2;
      }

      input:not([type='checkbox']) {
        @apply w-full;
      }

      &.wpforms-field-checkbox ul li {
        @apply flex items-center gap-4-8;

        input {
          @apply h-[20px] w-[20px];
          @apply checked:bg-black;
        }
      }
    }
  }

  .wpforms-submit-container {
    @apply pt-12-24;

    .wpforms-submit-spinner {
      @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform;
    }
  }

  em.wpforms-error,
  label.wpforms-error {
    @apply block pt-4-8 not-italic text-inherit;

    a {
      @apply !text-inherit !no-underline hover:!underline;
    }
  }

  .wpforms-confirmation-container {
    @apply border border-gowth-green bg-white/70 px-12-24 py-8-16;
  }
}
