/* Custom CSS for gutenberg grids */

/* PRICING PAGE */
.grid-pricing {
  .ticket-price {
    .wp-block-group {
      width: 100%;
    }

    .wp-block-button {
      @apply w-full;

      .wp-block-button__link {
        @apply justify-center;
      }
    }
  }

  .ticket-popular {
    position: relative;

    &::before {
      content: 'Most Popular';
      @apply absolute left-[-3px] top-0 w-[calc(100%+6px)] -translate-y-full bg-gowth-green py-1 text-center text-xs font-bold font-semibold uppercase tracking-[2.4px] text-black;
    }

    .wp-block-button .wp-block-button__link:not(.has-link-color) {
      @apply bg-gradient-gowth-tech hover:bg-hover-gradient-gowth-tech focus:bg-hover-gradient-gowth-tech;
    }

    @media (width <= 639px) {
      margin-top: 24px;
    }
  }
}

@media (width <= 1023px) {
  .wp-block-group.is-layout-grid.grid-pricing {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width <= 900px) {
  .wp-block-group.is-layout-grid.grid-2-cols {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .wp-block-group.is-section-tracks {
    background-image: none !important;
  }
}

@media (width <= 639px) {
  .wp-block-group.is-layout-grid.grid-pricing {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* MEDIA KIT */
.grid-media-kit-highlight {
  .column-1 {
    @apply md:border-r md:border-grey;
  }

  .column-2 {
    @apply border-t border-grey md:border-none;
  }

  .wp-block-image {
    @apply w-fit;
  }
}

/* HOMEPAGE FEATURES */
.grid-homepage-features {
  .column-1 {
    @apply md:border-r md:border-grey;
  }

  .column-2 {
    @apply grid grid-rows-[auto,1fr,auto] border-t border-grey lg:border-none;
  }

  .wp-block-image {
    @apply w-fit;
  }
}

/* BLOG ARCHIVE */
/* .blog-archive-grid {
  article.post:nth-child(1) {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 550px), 1fr));
    grid-template-rows: auto 1fr;

    .entry-thumbnail {
      grid-area: 1 / 1 / 3 / 2;
      @apply lg:border-r lg:border-grey;
    }

    .entry-title a {
      @apply lg:!text-3xl lg:leading-[120%];
    }

    .entry-content {
      @apply lg:p-12-24;
    }

    .entry-summary {
      @apply !text-base lg:!line-clamp-5;
    }
  }
} */
