.wp-block-button {
  --border-color: theme(colors.tech-blue);
  --background: theme(colors.tech-blue);
  --color: theme(colors.black);
  --hover-border-color: theme(colors.gowth-green);
  --hover-background: theme(colors.gowth-green);
  --hover-color: theme(colors.black);
  --outline-color: theme(colors.gowth-green);
  --border-w: 1px;
  --pad-x: 24px;
  --pad-y: 6px;

  @apply inline-grid text-[16px];

  .wp-block-button__link {
    @apply w-full rounded-full font-semibold uppercase tracking-[0.8px] text-inherit transition-colors duration-300;
    padding-inline: var(--pad-x);
    padding-block: var(--pad-y);
    border-width: var(--border-w);

    &:focus {
      outline: 1px solid var(--outline-color);
      outline-offset: 2px;
    }
  }

  &.is-size-small .wp-block-button__link:not(.is-style-subtle) {
    --pad-x: 16px;
    --pad-y: 2px;
  }

  .wp-block-button__link:not(.has-link-color) {
    background-color: var(--background);
    color: var(--color);
    border-color: var(--border-color);

    &:hover,
    &:focus {
      background-color: var(--hover-background);
      color: var(--hover-color);
      border-color: var(--hover-border-color);
    }
  }

  /* Outline Style */
  &.is-style-outline .wp-block-button__link:not(.has-link-color) {
    --background: transparent;
    --color: theme(colors.tech-blue);
    --hover-border-color: theme(colors.gowth-green);
    --hover-background: theme(colors.transparent);
    --hover-color: theme(colors.gowth-green);
    --border-w: 2px;
  }

  /* Subtle Style */
  &.is-style-subtle .wp-block-button__link:not(.has-link-color) {
    --background: transparent;
    --color: theme(colors.tech-blue);
    --border-color: transparent;
    --hover-background: transparent;
    --hover-border-color: theme(colors.transparent);
    --hover-color: theme(colors.gowth-green);
    --outline-color: transparent;
    --pad-x: 0;
    --pad-y: 0;

    @apply underline-offset-4;

    &:focus,
    &:hover {
      @apply underline;
    }
  }
}

/* On Colour Section Style */
.is-style-section-colour {
  .wp-block-button {
    .wp-block-button__link {
      --background: theme(colors.black);
      --color: theme(colors.white);
      --border-color: theme(colors.black);
      --hover-background: theme(colors.gowth-green);
      --outline-color: theme(colors.black);
      --hover-border-color: theme(colors.gowth-green);
    }

    &.is-style-outline .wp-block-button__link {
      --color: rgba(255 255 255 / 90%);
      --border-color: rgba(255 255 255 / 70%);
      --hover-background: var(--color-maroon-primary);
      --hover-color: rgba(255 255 255 / 100%);
    }

    &.is-style-subtle .wp-block-button__link {
      --color: #fff;
      --hover-color: #fff;
      --hover-border-color: transparent;
    }
  }
}

.wp-block-button[class*='has-icon__'] {
  .wp-block-button__link {
    display: flex;
    gap: 0.5em;
    align-items: center;

    span {
      line-height: 0;
    }

    svg {
      width: 1.2em;
      height: 1.2em;
      color: currentColor;
      fill: currentColor;
    }
  }

  &.has-icon__arrow-long-right,
  &.has-icon__arrow-long-left {
    .wp-element-button .wp-block-button__link-icon {
      @apply transition-transform duration-150 ease-out-expo;
    }
  }

  &.has-icon__arrow-long-right {
    &:hover,
    &:focus-within {
      .wp-block-button__link-icon {
        transform: translateX(0.25em);
      }
    }
  }

  &.has-icon__arrow-long-left {
    &:hover,
    &:focus-within {
      .wp-block-button__link-icon {
        transform: translateX(-0.25em);
      }
    }
  }
}
