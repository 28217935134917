/* This applies to the post feed on archives. If only one post is displayed, it will be styled differently.
Saves me having to add an attribute to the custom block */
.featured-post .post {
  .entry-title {
    @apply text-lg sm:text-xl lg:text-2xl lg:leading-[120%];
  }

  .entry-thumbnail {
    @apply md:min-h-[320px];
  }

  .entry-content {
    @apply lg:p-12-24;
  }

  .entry-summary {
    @apply !text-base md:line-clamp-3 xl:line-clamp-5;
  }
}

.post {
  .entry-title {
    @apply text-lg;
  }

  .entry-thumbnail {
    @apply min-h-[240px];
  }

  .entry-summary {
    @apply line-clamp-2;
  }
}
