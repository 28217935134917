/* Custom Gutenberg format - replaces text with an icon */
.in-text-format {
  @apply relative inline-flex overflow-hidden whitespace-nowrap indent-[100%];
  width: 2ch;
  height: 2ch;
  margin-inline: -0.4ch;

  &::before {
    content: '';
    @apply absolute inset-0 block bg-contain bg-center bg-no-repeat;
    background-image: url('@src/images/inventures-icon-white.svg');
  }
}
