/*
  This file is a fix for a bug - for some reason gutenberg is not loading the built in common.css file
  consistantly. This file is a copy of the common.css file.
 */
@media (min-resolution: 192dpi) {
  :root {
    --wp-admin-border-width-focus: 1.5px;
  }
}

.wp-element-button {
  cursor: pointer;
}

:root {
  --wp--preset--font-size--normal: 16px;
  --wp--preset--font-size--huge: 42px;
}

:root .has-very-light-gray-background-color {
  background-color: #eee;
}

:root .has-very-dark-gray-background-color {
  background-color: #313131;
}

:root .has-very-light-gray-color {
  color: #eee;
}

:root .has-very-dark-gray-color {
  color: #313131;
}

.has-regular-font-size {
  font-size: 1em;
}

.has-larger-font-size {
  font-size: 2.625em;
}

.has-normal-font-size {
  font-size: var(--wp--preset--font-size--normal);
}

.has-huge-font-size {
  font-size: var(--wp--preset--font-size--huge);
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-left {
  text-align: left;
}

.has-text-align-right {
  text-align: right;
}

#end-resizable-editor-section {
  display: none;
}

.aligncenter {
  clear: both;
}

.items-justified-left {
  justify-content: flex-start;
}

.items-justified-center {
  justify-content: center;
}

.items-justified-right {
  justify-content: flex-end;
}

.items-justified-space-between {
  justify-content: space-between;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #ddd;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

html :where(.has-border-color) {
  border-style: solid;
}

html :where([style*='border-top-color']) {
  border-top-style: solid;
}

html :where([style*='border-right-color']) {
  border-right-style: solid;
}

html :where([style*='border-bottom-color']) {
  border-bottom-style: solid;
}

html :where([style*='border-left-color']) {
  border-left-style: solid;
}

html :where([style*='border-width']) {
  border-style: solid;
}

html :where([style*='border-top-width']) {
  border-top-style: solid;
}

html :where([style*='border-right-width']) {
  border-right-style: solid;
}

html :where([style*='border-bottom-width']) {
  border-bottom-style: solid;
}

html :where([style*='border-left-width']) {
  border-left-style: solid;
}

html :where(img[class*='wp-image-']) {
  height: auto;
  max-width: 100%;
}

:where(figure) {
  margin: 0 0 1em;
}

html :where(.is-position-sticky) {
  --wp-admin--admin-bar--position-offset: var(
    --wp-admin--admin-bar--height,
    0px
  );
}

@media screen and (max-width: 600px) {
  html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: 0px;
  }
}
