@font-face {
  font-display: swap;
  font-family: 'degular_mono';
  src:
    url('@src/fonts/degularmono-regular-webfont.woff2') format('woff2'),
    url('@src/fonts/degularmono-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'degular_mono';
  src:
    url('@src/fonts/degularmono-italic-webfont.woff2') format('woff2'),
    url('@src/fonts/degularmono-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: 'degular_mono';
  src:
    url('@src/fonts/degularmono-semibold-webfont.woff2') format('woff2'),
    url('@src/fonts/degularmono-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'degular_mono';
  src:
    url('@src/fonts/degularmono-semibolditalic-webfont.woff2') format('woff2'),
    url('@src/fonts/degularmono-semibolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: 'degular_mono';
  src:
    url('@src/fonts/degularmono-bold-webfont.woff2') format('woff2'),
    url('@src/fonts/degularmono-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-display: swap;
  font-family: 'degular_mono';
  src:
    url('@src/fonts/degularmono-bolditalic-webfont.woff2') format('woff2'),
    url('@src/fonts/degularmono-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

html {
  @apply font-sans antialiased;
}

/* Headings */
h1 {
  @apply text-5xl font-semibold tracking-[1.36px];
}

h2 {
  @apply text-4xl font-semibold;
}

h3 {
  @apply text-3xl font-semibold;
}

h4 {
  @apply text-2xl font-semibold;
}

h5 {
  @apply text-xl font-semibold;
}

h6 {
  @apply text-base font-semibold;
}

ol.wp-block-list,
ol.wp-block-footnotes {
  @apply antialiased;
  @apply list-decimal;
}

ol.foo {
  @apply antialiased;
  @apply list-decimal;
}

ul.wp-block-list {
  @apply grid gap-2-4 antialiased;
  @apply list-disc;

  ul {
    @apply list-circle;
  }

  li mark.has-inline-color.has-gowth-green-color {
    @apply uppercase tracking-[3.6px];
  }

  &.is-style-in-marker {
    @apply gap-8-16 pl-[3ch];

    li {
      @apply relative list-none gap-2-4;

      &::marker,
      &::-webkit-details-marker {
        content: '';
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        translate: -3ch 0.3ch;
        width: 1.2em; /* Match the SVG dimensions */
        height: 1.2em;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="%2300FFAB"><path d="M6.6956 1H9.33333L6.63774 23H4L6.6956 1Z" /><path d="M13.0206 3.35766H10.9663L11.2907 1H15.4408L15.2894 20.6423H17.302L16.9777 23H12.8276L13.0206 3.35766ZM10.9829 3.23168H12.5448L11.0195 22.9987H8.26667L10.9846 3.23295L10.9829 3.23168ZM17.2471 1H20L17.2837 20.7671H15.7218L17.2471 1Z" /></svg>')
          no-repeat center center;
        background-size: contain;
      }
    }
  }

  &.is-style-checkmark-marker {
    @apply gap-8-16 pl-[3ch];

    li {
      @apply relative list-none;

      &::marker,
      &::-webkit-details-marker {
        content: '';
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0%;
        translate: -3ch 0.5ch;
        width: 1.2em; /* Match the SVG dimensions */
        height: 1.2em;
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none"><path d="M17.3479 1.56384L6.7479 12.1638C6.65402 12.2585 6.52622 12.3117 6.3929 12.3117C6.25958 12.3117 6.13178 12.2585 6.0379 12.1638L0.6479 6.7738C0.55324 6.68 0.5 6.5522 0.5 6.4188C0.5 6.2855 0.55324 6.1577 0.6479 6.0638L1.3479 5.3638C1.44178 5.2692 1.56958 5.2159 1.7029 5.2159C1.83622 5.2159 1.96402 5.2692 2.0579 5.3638L6.3879 9.6938L15.9379 0.143843C16.1357 -0.0479475 16.4501 -0.0479475 16.6479 0.143843L17.3479 0.853843C17.4426 0.947723 17.4958 1.07552 17.4958 1.20884C17.4958 1.34216 17.4426 1.46995 17.3479 1.56384Z" fill="%2300FFAB"/></svg>')
          no-repeat center center;
        background-size: contain;
      }
    }
  }
}
