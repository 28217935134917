.is-style-in-mask {
  mask-image: url('@images/masthead-image-mask.svg');
  mask-size: auto 105%;
  mask-repeat: no-repeat;
  mask-position: center right;

  /* img {
    mask-image: linear-gradient(
      to right,
      rgba(0 0 0 / 100%) 65%,
      rgba(0 0 0 / 0%) 92%
    );
  } */
}
