@import 'tailwindcss/base';
@import 'base/common.css';
@import 'base/type.css';
@import 'base/formats.css';
@import 'base/post.css';

@import 'tailwindcss/components';
@import 'components/buttons.css';
@import 'components/image.css';
@import 'components/menus.css';
@import 'components/loaders.css';
@import 'components/forms.css';
@import 'components/accordion.css';
@import 'components/grids.css';
@import 'components/animations.css';

/*
@import 'components/social-links.css';
*/

@import 'tailwindcss/utilities';

@layer base {
  :root {
    /* Logo */
    --header-logo: utopia.clamp(120, 146);

    /*
      Global Padding
      Used by gutenburg to center the content.
      See the theme.json file
    */
    --max-sc-width: calc(100vw - utopia.clamp(20, 40) * 2);

    --global-padding-inline: calc(
      50vw - min(theme(screens.2xl), var(--max-sc-width)) / 2
    );

    --half-screen-column-padding: calc(
      50vw - min(theme(screens.2xl), 1800px) / 2
    );

    /* Using vw won't work in the gutenberg editor */
    :where(.editor-styles-wrapper) {
      --max-sc-width: 100%;
      --global-padding-inline: 0;

      --half-screen-column-padding: calc(
        50% - min(theme(screens.2xl), 1800px) / 2
      );
    }

    --sponsor-logo-size: utopia.clamp(140, 240);
  }
}

@layer utilities {
  .is-style-subheading {
    @apply text-base uppercase tracking-[3.6px] text-gowth-green;
  }
}

.grecaptcha-badge {
  z-index: 100;
}

.global-padding-left {
  padding-left: var(--global-padding-inline) !important;
}

.global-padding-right {
  padding-right: var(--global-padding-inline) !important;
}
